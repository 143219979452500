import { NavLink } from "react-router-dom";
import { useLockBodyScroll } from "@uidotdev/usehooks";
import { links } from "./header";

interface MobileNavProps {
  open: boolean;
  onOpenChange: (value: boolean) => void;
}

function MobileNav({ open, onOpenChange }: MobileNavProps): JSX.Element {
  const url = import.meta.env.VITE_DASHBOARD_URL;
  useLockBodyScroll();
  return (
    <div className="fixed inset-0 top-16 z-20 grid h-[calc(100vh-4rem)] bg-black/25 backdrop-blur-sm grid-flow-row auto-rows-max overflow-auto p-3 pb-32 shadow-md animate-in slide-in-from-bottom-80 lg:hidden">
      <div className="relative z-50 grid gap-6 rounded-md bg-popover p-4 text-popover-foreground shadow-md">
        <h2 className="text-foreground font-semibold text-2xl">Menu</h2>
        <ul>
          {links.map((item) => (
            <li key={item.label} className="py-3 font-medium">
              <NavLink
                onClick={() => onOpenChange(!open)}
                to={item.path}
                className={({ isActive }) =>
                  isActive
                    ? "flex flex-row items-center border-l-4 border-primary text-foreground px-4 gap-x-2"
                    : "flex flex-row items-center text-muted-foreground px-5 hover:text-foreground gap-x-2 transition"
                }
              >
                {item.label}
              </NavLink>
            </li>
          ))}
        </ul>
        <h2 className="text-foreground font-semibold text-2xl">
          Customer Area
        </h2>
        <div>
          <div className="py-3 font-medium">
            <NavLink
              onClick={() => onOpenChange(!open)}
              to={`${url}/auth/register`}
              className={({ isActive }) =>
                isActive
                  ? "flex flex-row items-center border-l-4 border-primary text-foreground px-4 gap-x-2"
                  : "flex flex-row items-center text-muted-foreground px-5 hover:text-foreground gap-x-2 transition"
              }
            >
              Register
            </NavLink>
          </div>
          <div className="py-3 font-medium">
            <NavLink
              onClick={() => onOpenChange(!open)}
              to={`${url}`}
              className={({ isActive }) =>
                isActive
                  ? "flex flex-row items-center border-l-4 border-primary text-foreground px-4 gap-x-2"
                  : "flex flex-row items-center text-muted-foreground px-5 hover:text-foreground gap-x-2 transition"
              }
            >
              Login
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MobileNav;
