import ReactGA from "react-ga4";
import { Metric } from "web-vitals";

const trackingId = import.meta.env.VITE_GA_TRACKING_ID;

ReactGA.initialize(trackingId, {
  gaOptions: {
    siteSpeedSampleRate: 100,
    cookieDomain: "auto",
  },
});

ReactGA.ga("set", "linker", {
  domains: ["thunderproxy.com", "dashboard.thunderproxy.com"],
});

export const sendToAnalytics = (metric: Metric): void => {
  const metricName = metric.name;

  const webVitalMetrics = [
    "LCP", // Largest Contentful Paint
    "INP", // Interaction to Next Paint
    "CLS", // Cumulative Layout Shift
    "FCP", // First Contentful Paint
    "FID", // First Input Delay
    "TTFB", // Time to First Byte
  ];

  if (webVitalMetrics.includes(metricName)) {
    ReactGA.event({
      category: "Web Vitals",
      action: metric.name,
      label: metric.id,
      value: Math.round(metric.value),
      nonInteraction: true, // Include this to avoid affecting bounce rate
    });
  }
};
