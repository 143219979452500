import HeroSection from "@/components/home/hero";
import { Helmet } from "react-helmet-async";
import loadable from "@loadable/component";

const FeatureSection = loadable(
  () => import("@/components/home/feature-section"),
);
const CtaSection = loadable(() => import("@/components/home/cta-section"));
const ProductsOverview = loadable(
  () => import("@/components/home/products-overview"),
);
const PaymentMethods = loadable(
  () => import("@/components/home/payment-methods"),
);
const Cta2Section = loadable(() => import("@/components/home/cta2-section"));

export default function HomePage() {
  return (
    <>
      <Helmet>
        <title>
          thunderproxy - Buy Residential Proxies - Over 30M Residential IPs
        </title>
        <meta
          name="description"
          content="Affordable and reliable proxies for all your needs, including Datacenter Proxies, Residential Proxies, and Static ISP Proxies."
        />
        <link rel="canonical" href="https://thunderproxy.com" />
      </Helmet>
      <HeroSection />
      <FeatureSection />
      <CtaSection />
      <ProductsOverview />
      <PaymentMethods />
      <Cta2Section />
    </>
  );
}
